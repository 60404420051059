
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import FindWordsContainer from '../../components/tests/findWords/FindWordsContainer.vue';
import FindWordsTutorialContainer from '../../components/tests/findWords/FindWordsTutorialContainer.vue';
import store from '../../store';

@Component({
  components: {
    FindWordsTutorialContainer,
    FindWordsContainer,
    GeneralLayout,
    GeneralHeader,
  },
})
export default class WoerterFinden extends Vue {
  wordsModule: WordsModule = getModule(WordsModule, store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  get doneTutorial(): boolean {
    return this.wordsModule.hasDoneTutorial;
  }

  async beforeRouteLeave(to: any, from: any, next: () => void): Promise<void> {
    await (store as any).restored;
    this.userModule.stopActiveAudio();
    // try {
    //   await this.wordsModule.saveStatus(this.userModule.currentUser._id);
    //   this.snackbarModule.showMessage({
    //     message: 'Wörter finden gespeichert!',
    //     isSuccess: true,
    //   });
    // } catch (error) {
    //   this.snackbarModule.showMessage({
    //     message: 'Leider konnte ich nicht speichern.',
    //     isSuccess: false,
    //   });
    // }
    next();
  }
}
